import * as React from "react"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import CardAppearAnimaton from "../animations/CardAppearAnimation"
import { useOnVisibleOnce } from "../hooks/useOnVisibleOnce"

const Card = styled(Link)({
    backgroundColor: "#FFEB3B",
    borderRadius: "1em",
    textDecoration: "none",
    margin: "2em",
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.8)",
    transition: "box-shadow 300ms, width 300ms",
    width: "85%",
    "&:hover": {
        width: "90%",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.8)",
    },
    "@media(max-width: 360px)": {
        width: "75%",
        "&:hover": {
            width: "80%"
        }
    }
}, ({ animate }) => ({
    animation: animate ? `${CardAppearAnimaton} 1.5s ease` : "none"
}))

const CardTitle = styled.p({
    textTransform: "uppercase",
    fontSize: "1.7em",
    fontWeight: "500",
    textAlign: "center",
    letterSpacing: "0.06em",
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    color: "#000",
})

export default function ProductCard({ title, image, link }) {

    const [ref, animate] = useOnVisibleOnce()

    return (
        <Card ref={ref} animate={animate} to={link}>
            <CardTitle>{title}</CardTitle>
            <GatsbyImage image={image} alt={title} />
        </Card>
    )
}
