import * as React from "react"
import MainContainer from "../components/MainContainer"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import ProductCard from "../components/ProductCard"
import { getImage } from "gatsby-plugin-image"
import PageHeader from "../components/PageHeader"
import SEO from "../components/SEO"

const ProductsContainer = styled.div({
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "2em",
    "@media(max-width: 1060px)": {
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    "@media(max-width: 770px)": {
        gridTemplateColumns: "repeat(1, 1fr)",
    },
    "@media(max-width: 320px)": {
        gap: "0.5em"
    },
})

export default function ProductsPage() {

    const data = useStaticQuery(AllProducts)
    const products = data.products.nodes
    const header = data.header
    const { seoTitle, seoDescription, title, paragraphs } = header

    const productsList = products.map(({ id, title, image, tag }) =>
        <ProductCard key={id} title={title} image={getImage(image)} link={`/products/${tag}`} />
    )

    return (
        <MainContainer>
            <SEO title={seoTitle} description={seoDescription} pagename="products" />
            <PageHeader title={title} paragraphs={paragraphs}/>
            <ProductsContainer>
                {productsList}
            </ProductsContainer>
        </MainContainer>
    )
}

const AllProducts = graphql`
query AllProducts {
    products: allProductsJson {
        nodes {
            id
            title
            tag
            image: imagePath {
                childImageSharp {
                    gatsbyImageData(width: 700, height: 600)
                }
            }
        }
    }
    header: headersJson(id: {eq: "products"}) {
        title
        paragraphs
        seoTitle
        seoDescription
    }
}`
